.lia-type {
  padding: 0 5px;
  margin-right: 5px;
  width: fit-content;
  line-height: 1.81818181818;
  color: var(--lia-bs-body-color);
  border: 1px solid var(--lia-bs-black);
  border-radius: 100vw;
  font-size: var(--lia-font-size-xxs);
  font-weight: var(--lia-font-weight-md);
}
